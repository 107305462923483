import {
   ButtonLabelWithHighlightView,
   ButtonView,
   createDropdown,
   filterGroupAndItemNames,
   icons,
   LabelWithHighlightView,
   ListItemGroupView,
   ListItemView,
   ListView,
   Plugin,
   SearchTextView,
} from 'ckeditor5';
import { fieldTypeIsNumeric } from 'utils';

class FilteredListView extends ListView {
   /**
    * @inheritDoc
    */
   filter(regExp) {
      return filterGroupAndItemNames(regExp, this.items);
   }
}

function createListItemView(locale, definition) {
   const item = new ListItemView(locale);
   const labelView = new ButtonLabelWithHighlightView();
   const button = new ButtonView(locale, labelView);

   item.children.add(button);

   button.set({
      id: definition.id,
      label: definition.label,
      withText: true,
   });

   if (definition.model) {
      button.bind(...Object.keys(definition.model)).to(definition.model);
   }

   button.delegate('execute').to(item);

   return item;
}

function createSearchTextView(locale, listView) {
   return new SearchTextView(locale, {
      filteredView: listView,
      queryView: {
         label: 'Search',
      },
      infoView: {
         text: {
            notFound: {
               primary: 'No results found',
            },
            noSearchableItems: {
               primary: 'No items available',
            },
         },
      },
   });
}

function createListView(locale, definitions) {
   const listView = new FilteredListView(locale);
   const ungroupedItems = [];
   const groups = [];

   for (const definition of definitions) {
      if ('groupId' in definition) {
         const label = definition.groupLabel || definition.groupId;

         groups.push(createGroupView(locale, label, definition.itemDefinitions));
      } else {
         ungroupedItems.push(definition);
      }
   }

   if (ungroupedItems.length) {
      groups.push(createGroupView(locale, 'Other', ungroupedItems));
   }

   listView.items.addMany(groups);

   listView.items.delegate('execute').to(listView);

   return listView;
}

function createGroupView(locale, label, definitions) {
   const groupView = new ListItemGroupView(locale, new LabelWithHighlightView());

   groupView.label = label;

   for (const definition of definitions) {
      groupView.items.add(createListItemView(locale, definition));
   }

   groupView.items.delegate('execute').to(groupView);

   return groupView;
}

export class InsertEnvoriaTable extends Plugin {
   constructor(editor) {
      super(editor);
   }

   init() {
      this.editor.ui.componentFactory.add('insertEnvoriaTable', (locale) => {
         const tables = this.editor.config.get('envoriaTables.tables') ?? [];

         const listView = createListView(locale, tables);

         const searchView = createSearchTextView(locale, listView);

         searchView.extendTemplate({
            attributes: {
               style: {
                  padding: '10px',
               },
            },
         });

         const dropdown = createDropdown(locale);

         dropdown.buttonView.set({
            icon: icons.table,
         });

         dropdown.listView = listView;

         listView.delegate('execute').to(dropdown);

         dropdown.panelView.children.add(searchView, 0);

         dropdown.on('execute', (evt) => {
            const id = evt.source.id;

            if (id) {
               const table = tables.flatMap(({ itemDefinitions }) => itemDefinitions).find(({ id: tableId }) => tableId === id);

               if (table) {
                  const aggregationRow = table.columns.some(({ aggregation }) => aggregation) ? 1 : 0;

                  this.editor.model.change((writer) => {
                     this.editor.execute('insertTable', {
                        headingRows: 1,
                        headingColumns: 1,
                        rows: table.rows.length + aggregationRow + 1,
                        columns: table.columns.length + 1,
                     });

                     const tableModel = this.editor.model.document.selection
                        .getFirstPosition()
                        .getAncestors()
                        .find((item) => item.name == 'table');

                     const tableWalker = this.editor.plugins.get('TableUtils').createTableWalker(tableModel);

                     writer.setAttribute('tableWidth', '100%', tableModel);

                     for (const slot of tableWalker) {
                        const rowIndex = slot.row - 1;
                        const columnIndex = slot.column - 1;

                        const rangeWrappingCellContent = writer.createRangeIn(slot.cell);

                        if (rangeWrappingCellContent) {
                           const sel = writer.createSelection(rangeWrappingCellContent);

                           // I'm using `insertContent()` instead of `writer.insertText()` because it's
                           // more future-proof (https://github.com/ckeditor/ckeditor5-table/issues/114).
                           // Also, we use a <tableCell>[...cell content...]</tableCell> to make sure we
                           // override the entire content (there can be an empty paragraph since ckeditor5@12.0.0).
                           // or, if `content` contains model docfrags/items:
                           // editor.model.insertContent( cellContent, sel );
                           if (slot.row === 0 && slot.column === 0) {
                              if (table.columnName) {
                                 this.editor.model.insertContent(writer.createText(table.columnName), sel);
                              }
                           } else if (slot.row === 0 && slot.column > 0) {
                              if (table.columns[columnIndex]) {
                                 this.editor.model.insertContent(writer.createText(table.columns[columnIndex].name), sel);
                              }
                           } else if (slot.column === 0 && slot.row > 0) {
                              if (table.rows[rowIndex]) {
                                 this.editor.model.insertContent(writer.createText(table.rows[rowIndex].name), sel);
                              }
                           } else if (slot.row > 0 && slot.column > 0) {
                              writer.setAttribute(
                                 'tableCellHorizontalAlignment',
                                 fieldTypeIsNumeric(table.columns[columnIndex]?.type) ||
                                    ['sum_row', 'relation'].includes(table.columns[columnIndex].aggregation)
                                    ? 'right'
                                    : 'left',
                                 slot.cell
                              );

                              if (table.columns[columnIndex]) {
                                 let id;

                                 if (table.rows[rowIndex]) {
                                    id = `${table.rows[rowIndex].identifier}_${table.columns[columnIndex].identifier}`;
                                 } else if (aggregationRow && rowIndex === table.rows.length && table.columns[columnIndex].aggregation) {
                                    id = `${table.id}_AGG_${table.columns[columnIndex].identifier}`;
                                 }

                                 if (id) {
                                    this.editor.model.insertContent(
                                       writer.createElement('mergeField', {
                                          id,
                                          ...(['sum_row'].includes(table.columns[columnIndex].aggregation) ||
                                          (aggregationRow && rowIndex === table.rows.length)
                                             ? { bold: true }
                                             : {}),
                                       }),
                                       sel
                                    );
                                 }
                              }
                           }
                        }
                     }

                     const groupElement = writer.createElement('tableColumnGroup');

                     [1, ...table.columns].forEach(() => {
                        const columnElement = writer.createElement('tableColumn');
                        writer.setAttribute('columnWidth', `${(1 / table.columns.length) * 100}%`, columnElement);

                        writer.append(columnElement, groupElement);
                     });

                     writer.append(groupElement, tableModel);

                     const captionElement = writer.createElement('caption');
                     writer.appendText(table.label, captionElement);
                     writer.append(captionElement, tableModel);
                  });
               }
            }
         });

         return dropdown;
         // Will render a dropdown with a list in the panel containing two items.
      });

      console.debug('Envoria Table plugin has been registered');
   }
}
