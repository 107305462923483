import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { activeOrganisationId$, characteristics$ } from 'context/OrganisationContext/query';
import { context$ as periodContext$ } from 'context/PeriodContext/query';
import { parseISO } from 'date-fns';
import { isEqual, uniqWith } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { allResponseEntities$ } from 'state/BusinessActivities/query';
import { TaxonomyAlignmentType, TaxonomyContributionType } from 'utils/enum';
import store from './store';

const nestCriteria = (items, id = undefined) =>
   items
      .filter((item) => item?.parent?.id === id)
      .sort((a, b) => (a?.key && b?.key ? a.key.localeCompare(b.key) : a.id - b.id))
      .map((item) => ({ ...item, criteria: nestCriteria(items, item.id) }));

export const query = createEntityQuery(store, { sortBy: 'updatedAt', sortByOrder: Order.DESC });

export const loading$ = query.selectLoading();

export const relevantCriteria$ = combineQueries([query.selectAll(), characteristics$]).pipe(
   map(([criteria, characteristics]) =>
      criteria.filter(
         (criterion) =>
            Array.isArray(characteristics) &&
            (characteristics.length === 0 ||
               characteristics.some((c) => c.value === criterion.context || ['PSF', 'ARTICLE_18'].includes(criterion.context)))
      )
   )
);

export const responsesByActivity$ = combineQueries([
   relevantCriteria$,
   allResponseEntities$,
   periodContext$,
   activeOrganisationId$,
   characteristics$,
]).pipe(
   map(([allCriteria, responses, periodContext, activeOrganisationId, characteristics]) =>
      uniqWith(
         allCriteria.filter((criterion) => criterion?.activity?.id).map((criterion) => criterion.activity),
         isEqual
      )
         .sort((a, b) => (a?.key ?? '').localeCompare(b?.key ?? ''))
         .map((activity) => ({
            ...activity,
            contribution:
               !(Array.isArray(characteristics) && characteristics.some((c) => c.value === 'CONTROVERSIAL_WEAPON_EXPOSURE')) &&
               allCriteria
                  .filter((criterion) => criterion?.activity?.id === activity.id && criterion?.answerable)
                  .every((criterion) =>
                     responses.some(
                        (response) =>
                           response?.criterion?.id === criterion.id &&
                           response?.organisation?.id === activeOrganisationId &&
                           parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                           parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                           ['NO', 'NA'].includes(response?.type?.code)
                     )
                  ) &&
               allCriteria
                  .filter((criterion) => criterion?.activity?.id === activity.id && criterion?.answerable)
                  .some((criterion) =>
                     responses.some(
                        (response) =>
                           response?.criterion?.id === criterion.id &&
                           response?.organisation?.id === activeOrganisationId &&
                           parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                           parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                           ['NO'].includes(response?.type?.code)
                     )
                  )
                  ? { type: { code: TaxonomyContributionType.MINIMUM_SAFEGUARDS } }
                  : (Array.isArray(characteristics) && characteristics.some((c) => c.value === 'CONTROVERSIAL_WEAPON_EXPOSURE')) ||
                      allCriteria
                         .filter((criterion) => criterion?.activity?.id === activity.id && criterion?.answerable)
                         .some((criterion) =>
                            responses.some(
                               (response) =>
                                  response?.criterion?.id === criterion.id &&
                                  response?.organisation?.id === activeOrganisationId &&
                                  parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                                  parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                                  ['YES'].includes(response?.type?.code)
                            )
                         )
                    ? { type: { code: TaxonomyContributionType.NOT_QUALIFIED } }
                    : undefined,
            criteria: nestCriteria(
               allCriteria
                  .filter((criterion) => criterion?.activity?.id === activity.id)
                  .map((criterion) => ({
                     ...criterion,
                     response: responses.find(
                        (response) =>
                           response?.criterion?.id === criterion.id &&
                           response?.organisation?.id === activeOrganisationId &&
                           parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                           parseISO(response?.validTo) <= parseISO(periodContext?.to)
                     ),
                  }))
            ),
         }))
   ),
   distinctUntilChanged(isEqual)
);

export const someOpen$ = combineQueries([relevantCriteria$, allResponseEntities$, periodContext$, activeOrganisationId$]).pipe(
   map(
      ([criteria, responses, periodContext, activeOrganisationId]) =>
         criteria.filter((criterion) => criterion?.answerable && TaxonomyContributionType.MINIMUM_SAFEGUARDS === criterion?.contributionType?.code)
            .length ===
            criteria.filter(
               (criterion) =>
                  criterion?.answerable &&
                  TaxonomyContributionType.MINIMUM_SAFEGUARDS === criterion?.contributionType?.code &&
                  responses.some(
                     (response) =>
                        response?.criterion?.id === criterion?.id &&
                        response?.organisation?.id === activeOrganisationId &&
                        parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                        parseISO(response?.validTo) <= parseISO(periodContext?.to)
                  )
            ).length &&
         !criteria.some(
            (criterion) =>
               criterion?.answerable &&
               TaxonomyContributionType.MINIMUM_SAFEGUARDS === criterion?.contributionType?.code &&
               responses.some(
                  (response) =>
                     response?.criterion?.id === criterion?.id &&
                     response?.organisation?.id === activeOrganisationId &&
                     parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                     parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                     response?.type?.code === 'YES'
               )
         )
   ),
   distinctUntilChanged()
);

export const allResponses$ = combineQueries([relevantCriteria$, allResponseEntities$, activeOrganisationId$, periodContext$]).pipe(
   map(([criteria, responses, activeOrganisationId, periodContext]) =>
      responses.filter(
         (response) =>
            criteria
               .filter(({ answerable }) => answerable === true)
               .map((criterion) => criterion.id)
               .includes(response?.criterion?.id) &&
            response.organisation.id === activeOrganisationId &&
            response.validFrom === periodContext?.from &&
            response.validTo === periodContext?.to
      )
   )
);

export const alignmentResult$ = combineQueries([
   relevantCriteria$,
   allResponseEntities$,
   periodContext$,
   activeOrganisationId$,
   characteristics$,
]).pipe(
   map(([criteria, responses, periodContext, activeOrganisationId, characteristics]) => {
      if (Array.isArray(characteristics) && characteristics.length === 0 && responses.length === 0) {
         return undefined;
      }
      if (
         !responses.some(
            (response) =>
               criteria
                  .filter(({ answerable }) => answerable === true)
                  .map((criterion) => criterion.id)
                  .includes(response?.criterion?.id) &&
               response?.organisation?.id === activeOrganisationId &&
               parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
               parseISO(response?.validTo) <= parseISO(periodContext?.to)
         ) &&
         !(Array.isArray(characteristics) && characteristics.some((c) => c.value === 'CONTROVERSIAL_WEAPON_EXPOSURE'))
      ) {
         return undefined;
      }

      if (
         criteria.some(
            (criterion) =>
               responses.find(
                  (response) =>
                     response?.criterion?.id === criterion?.id &&
                     response?.organisation?.id === activeOrganisationId &&
                     parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                     parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                     response?.type?.code === 'YES'
               ) &&
               (criterion.context === 'ARTICLE_18' || (Array.isArray(characteristics) && characteristics.some((c) => c.value === criterion.context)))
         ) ||
         (Array.isArray(characteristics) && characteristics.some((c) => c.value === 'CONTROVERSIAL_WEAPON_EXPOSURE'))
      ) {
         return TaxonomyAlignmentType.NOT_ALIGNED;
      }

      if (
         criteria
            .filter(({ answerable, context }) => answerable === true && context !== 'ARTICLE_18')
            .every((criterion) =>
               responses.find(
                  (response) =>
                     response?.criterion?.id === criterion?.id &&
                     response?.organisation?.id === activeOrganisationId &&
                     parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                     parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                     response?.type?.code === 'NO'
               )
            )
      ) {
         return TaxonomyAlignmentType.ALIGNED;
      }

      return undefined;
   })
);

export const status$ = query.select('status');
