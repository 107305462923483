/* eslint-disable sonarjs/public-static-readonly */
import {
   Alignment,
   AutoImage,
   Autoformat,
   BlockQuote,
   Bold,
   CloudServices,
   DataSchema,
   DecoupledEditor,
   EasyImage,
   Essentials,
   FontBackgroundColor,
   FontColor,
   FontFamily,
   FontSize,
   Heading,
   Highlight,
   HorizontalLine,
   Image,
   ImageCaption,
   ImageInsert,
   ImageResize,
   ImageStyle,
   ImageToolbar,
   Indent,
   IndentBlock,
   Italic,
   Link,
   List,
   ListProperties,
   Mention,
   PageBreak,
   Paragraph,
   PasteFromOffice,
   PictureEditing,
   RemoveFormat,
   Strikethrough,
   Table,
   TableCaption,
   TableCellProperties,
   TableColumnResize,
   TableProperties,
   TableToolbar,
   TextTransformation,
   TodoList,
   Underline,
   Undo,
} from 'ckeditor5';
import {
   AnnotationsUIs,
   Comments,
   DocumentOutline,
   ExportWord,
   FormatPainter,
   ImportWord,
   MergeFields,
   MultiLevelList,
   Pagination,
   PresenceList,
   RealTimeCollaborativeComments,
   RealTimeCollaborativeEditing,
   RealTimeCollaborativeRevisionHistory,
   RealTimeCollaborativeTrackChanges,
   RevisionHistory,
   TableOfContents,
   Template,
   TrackChanges,
} from 'ckeditor5-premium-features';

import { CustomTagPlugin } from './Plugins/CustomTagPlugin';

// eslint-disable-next-line import/no-unresolved
import dePremLang from 'ckeditor5-premium-features/translations/de.js';
// eslint-disable-next-line import/no-unresolved
import itPremLang from 'ckeditor5-premium-features/translations/it.js';
// eslint-disable-next-line import/no-unresolved
import plPremLang from 'ckeditor5-premium-features/translations/pl.js';
// eslint-disable-next-line import/no-unresolved
import roPremLang from 'ckeditor5-premium-features/translations/ro.js';
// eslint-disable-next-line import/no-unresolved
import deLang from 'ckeditor5/translations/de.js';
// eslint-disable-next-line import/no-unresolved
import itLang from 'ckeditor5/translations/it.js';
// eslint-disable-next-line import/no-unresolved
import plLang from 'ckeditor5/translations/pl.js';
// eslint-disable-next-line import/no-unresolved
import roLang from 'ckeditor5/translations/ro.js';
// eslint-disable-next-line import/no-unresolved
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
// eslint-disable-next-line import/no-unresolved
import 'ckeditor5/ckeditor5.css';
import AnnotationsSidebarToggler from './Plugins/AnnotationsSidebarToggler';
import DocumentOutlineToggler from './Plugins/DocumentOutlineToggler';
import { InsertEnvoriaTable } from './Plugins/InsertEnvoriaTable';

export class DefaultReportEditor extends DecoupledEditor {
   static tokenUrl = '/auth/cs-token-endpoint/';
   static converterTokenUrl = '/auth/docx-converter-token-endpoint/';
   static webSocketUrl = 'wss://cs.ckeditor.envoria.app/ws';
   static uploadUrl = 'https://cs.ckeditor.envoria.app/easyimage/upload/';
   static converterUrl = 'https://docx-converter.ckeditor.envoria.app/v2/convert/';
   static licenseKey = 'Umd5bVVUMmI5S2VENXFNT1FFdUxwc0w5QzF0NFlLc1VkdFNwUml2SDllbVQ3WDA1QWFYR3l0aWotTWpBeU5URXdNams9';

   static builtinPlugins = [
      Alignment,
      AnnotationsUIs,
      AutoImage,
      Autoformat,
      BlockQuote,
      Bold,
      CloudServices,
      Comments,
      CustomTagPlugin,
      DataSchema,
      DocumentOutline,
      EasyImage,
      Essentials,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      FormatPainter,
      Heading,
      Highlight,
      HorizontalLine,
      Image,
      ImageCaption,
      ImageInsert,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImportWord,
      ExportWord,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      ListProperties,
      Mention,
      MergeFields,
      MultiLevelList,
      PageBreak,
      Pagination,
      Paragraph,
      PasteFromOffice,
      PictureEditing,
      PresenceList,
      RealTimeCollaborativeComments,
      RealTimeCollaborativeEditing,
      RealTimeCollaborativeRevisionHistory,
      RealTimeCollaborativeTrackChanges,
      RemoveFormat,
      RevisionHistory,
      Strikethrough,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TableOfContents,
      Template,
      TextTransformation,
      TodoList,
      TrackChanges,
      Underline,
      Undo,
   ];

   /**
    * Default configuration for the CKEditor instance.
    * https://ckeditor.com/docs/ckeditor5/latest/api/module_core_editor_editorconfig-EditorConfig.html
    * @type {import("ckeditor5").EditorConfig} EditorConfig
    */
   static defaultConfig = {
      exportWord: {
         tokenUrl: DefaultReportEditor.converterTokenUrl,
         converterUrl: `${DefaultReportEditor.converterUrl}html-docx`,
         converterOptions: {
            document: {
               size: 'A4',
               margin: {
                  top: '20mm',
                  bottom: '20mm',
                  right: '12mm',
                  left: '12mm',
               },
            },
         },
      },
      language: 'en',
      toolbar: {
         items: [
            'insertTemplate',
            '|',
            'insertEnvoriaTable',
            'insertMergeField',
            'previewMergeFields',
            '|',
            'revisionHistory',
            'trackChanges',
            'comment',
            'commentsArchive',
            '|',
            'fontsize',
            'fontColor',
            'fontBackgroundColor',
            'fontfamily',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'removeFormat',
            'highlight',
            'blockquote',
            '|',
            'alignment',
            'multiLevelList',
            'numberedList',
            'bulletedList',
            '|',
            'outdent',
            'indent',
            '|',
            'formatPainter',
            'link',
            'insertTable',
            'tableOfContents',
            'horizontalLine',
            '|',
            'uploadImage',
            'PageBreak',

            '|',
            'undo',
            'redo',
            '|',
            'pageNavigation',
            '|',
            'importWord',
            'exportWord',
            '|',
            'heading',
         ],
         shouldNotGroupWhenFull: true,
      },
      cloudServices: {
         tokenUrl: DefaultReportEditor.tokenUrl,
         webSocketUrl: DefaultReportEditor.webSocketUrl,
         uploadUrl: DefaultReportEditor.uploadUrl,
      },
      fontFamily: {
         supportAllValues: true,
      },
      fontSize: {
         options: [8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 44, 48, 54, 60, 66, 72],
         supportAllValues: true,
      },
      image: {
         toolbar: ['imageStyle:inline', 'imageStyle:block', 'imageStyle:side', '|', 'toggleImageCaption', 'imageTextAlternative', '|', 'comment'],
      },
      table: {
         contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties', 'toggleTableCaption'],
         tableToolbar: ['comment'],
      },
      heading: {
         options: [
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
         ],
      },
      comments: {
         editorConfig: {
            extraPlugins: [Mention],
         },
      },
      importWord: {
         tokenUrl: DefaultReportEditor.converterTokenUrl,
         converterUrl: `${DefaultReportEditor.converterUrl}docx-html`,
      },
      licenseKey: DefaultReportEditor.licenseKey,
      translations: [deLang, roLang, plLang, itLang, dePremLang, roPremLang, plPremLang, itPremLang],
      extraPlugins: [DocumentOutlineToggler, AnnotationsSidebarToggler, InsertEnvoriaTable],
      menuBar: {
         isVisible: true,
      },
      pagination: {
         // A4
         pageWidth: '21cm',
         pageHeight: '29.7cm',

         pageMargins: {
            top: '20mm',
            bottom: '20mm',
            left: '12mm',
            right: '12mm',
         },
      },
   };
}
