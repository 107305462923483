import { Currencies } from 'currencies-map';
import Qty from 'js-quantities';
import { unit } from 'mathjs';
import type { IntlShape } from 'react-intl';
import { KPIFieldType } from 'utils/enum';

const unitReplacement: Record<string, string | null> = {
   tonne: 't',
   'kg/mile': 'kg/mi',
   'kg/short ton': 'kg/tn',
   'kg/tonne': 'kg/t',
   'kg/room-night': 'kg',
   'kg/instance-hour': 'kg/h',
   'kg/CPU-hour': 'kg/h',
   'kg/passenger-km': 'kg/km',
   'kg/passenger-mile': 'kg/mile',
   'kg/person-night': 'kg',
   'kg/gal (US)': 'kg/gal',
   'kg/t-km': 'kg/tkm',
   'kg/L': 'kg/l',
   'room-night': null,
   'CPU-hour': 'h',
   'instance-hour': 'h',
   'passenger-km': 'km',
   'passenger-mile': 'mile',
   'person-night': null,
   'gal (US)': 'gal',
   L: 'l',
   'container-moved': null,
   mile: 'mi',
};

type Unit = string | null | undefined;

export function determineBaseUnit(unit: Unit): Unit {
   if (!unit) {
      return unit;
   }

   const [, baseUnit_] = (unit ?? '').includes('/') ? unit.split('/') : [];

   let baseUnit: Unit;

   if ((baseUnit_ ?? '').includes('-')) {
      [, baseUnit] = baseUnit_.split('-');
   } else {
      baseUnit = baseUnit_;
   }

   if (baseUnit && Currencies.names.has(baseUnit)) {
      return undefined;
   }

   return unitReplacement[baseUnit] ?? baseUnit;
}

function sanitizeUnit(unit: Unit): string {
   const replacementChars = ['/', '-'];
   let returnUnit = unit ?? '';

   switch (returnUnit) {
      case 'ha':
         returnUnit = 'hectare';
         break;
      case 'a':
         returnUnit = 'acre';
         break;
      default:
   }

   returnUnit = returnUnit
      .replace(' CO₂', '')
      .replace('²', '^2')
      .replace('³', '^3')
      .replace(/\B(\d)/g, '^$1');

   replacementChars.forEach((char) => {
      if (returnUnit.includes(char)) {
         returnUnit = returnUnit
            .split(char)
            .map((aUnit) => {
               if (aUnit === 'ha') {
                  return 'hectare';
               }
               if (aUnit === 'a') {
                  return 'acre';
               }
               return aUnit;
            })
            .join(char);
      }
   });

   return returnUnit;
}

export function convertUnit(unitFrom: string, unitTo: string, amount: number): number {
   const sourceUnit = sanitizeUnit(unitFrom);
   const targetUnit = sanitizeUnit(unitTo);

   if (sourceUnit === targetUnit) {
      return amount;
   }

   if ((sourceUnit.endsWith(' CO₂') && !targetUnit.endsWith(' CO₂')) || (!sourceUnit.endsWith(' CO₂') && targetUnit.endsWith(' CO₂'))) {
      throw new Error(JSON.stringify({ error: 'units_not_compatible', from: unitFrom ?? '', to: unitTo ?? '', amount }));
   }

   if ([sourceUnit, targetUnit].some((aUnit) => ['Gt', 'Mt'].includes(aUnit))) {
      return unit(`${amount}${sourceUnit}`).toNumber(targetUnit);
   }

   if ((!sourceUnit && targetUnit) || (targetUnit && !sourceUnit)) {
      throw new Error(JSON.stringify({ error: 'units_not_compatible', from: unitFrom ?? '', to: unitTo ?? '', amount }));
   }

   if (!Qty.parse(sourceUnit)) {
      throw new Error(JSON.stringify({ error: 'unknown_unit', unit: unitFrom, amount }));
   }
   if (!Qty.parse(targetUnit)) {
      throw new Error(JSON.stringify({ error: 'unknown_unit', unit: targetUnit, amount }));
   }
   if (Qty.parse(sourceUnit) && Qty.parse(targetUnit) && !Qty.parse(sourceUnit).isCompatible(Qty.parse(targetUnit))) {
      throw new Error(JSON.stringify({ error: 'units_not_compatible', from: unitFrom ?? '', to: unitTo ?? '', amount }));
   }

   return Qty.parse(`${amount}${sourceUnit}`).to(targetUnit).scalar;
}

export const formatOptions = [
   'compactDisplay',
   'currencySign',
   'currencyDisplay',
   'localeMatcher',
   'maximumFractionDigits',
   'maximumSignificantDigits',
   'minimumFractionDigits',
   'minimumSignificantDigits',
   'minimumIntegerDigits',
   'notation',
   'signDisplay',
   'useGrouping',
   'unitDisplay',
   'roundingPriority',
] as const;

export function determineDefaultStyle(element: {
   unit?: string;
   currencyCode?: string;
   currencyId?: string;
   currency?: string;
   type?: string;
}): string {
   if (element?.unit === '%') {
      return 'percent';
   }
   if (element?.unit) {
      return 'unit';
   }
   if (element?.currencyCode ?? element?.currencyId ?? element?.currency) {
      return 'currency';
   }
   if (element?.type === KPIFieldType.PERCENTAGE) {
      return 'percent';
   }
   return 'decimal';
}

export function getCurrencySymbol(intl: IntlShape, shortCode: string): string | undefined {
   const parts = intl.formatNumberToParts(0, {
      style: 'currency',
      currency: shortCode,
      currencyDisplay: 'narrowSymbol',
      signDisplay: 'always',
   });
   return parts.find((part) => part.type === 'currency')?.value;
}
