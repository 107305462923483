import { Container } from '@mui/material';

import FullPageLoadingIndicator from 'components/v2/FullPageLoadingIndicator';
import ReportEditorPage from 'pages/ReportEditor';
import React, { Suspense } from 'react';
import { Navigate } from 'react-router';
import { determineDashboardEntryPath, determineDefaultEntryPath } from './utils';

const NotFound = React.lazy(() => import('pages/NotFound/index'));
const NotAllowed = React.lazy(() => import('pages/NotAllowed/index'));
const Login = React.lazy(() => import('pages/Login/index'));
const Layout = React.lazy(() => import('pages/Layout/index'));
const KPIDetailPage = React.lazy(() => import('pages/KPI/index'));
const Overview = React.lazy(() => import('pages/Dashboard/index'));
const Profile = React.lazy(() => import('pages/User/index'));
const Reports = React.lazy(() => import('pages/Report/index'));
const Settings = React.lazy(() => import('pages/Settings'));
const ReportSubpages = React.lazy(() => import('pages/Reports'));
const Tasks = React.lazy(() => import('pages/Task/index'));
const TasksDetail = React.lazy(() => import('pages/Task/Detail'));
const Actions = React.lazy(() => import('pages/Action/index'));
const ActionDetail = React.lazy(() => import('pages/Action/Detail'));
const Taxonomy = React.lazy(() => import('pages/Taxonomy/index'));
const BusinessActivity = React.lazy(() => import('pages/Taxonomy/BusinessActivity'));
const MinimumSafeguards = React.lazy(() => import('pages/Taxonomy/MinimumSafeguards'));
const Financials = React.lazy(() => import('pages/Taxonomy/Financials'));
const TaxonomyDisclosures = React.lazy(() => import('pages/Taxonomy/Disclosures'));
const EmissionFootprints = React.lazy(() => import('pages/Emission/index'));
const EmissionResults = React.lazy(() => import('pages/Emission/EmissionResults'));
const ReductionPlan = React.lazy(() => import('pages/Emission/ReductionPlan'));
const EmissionDetail = React.lazy(() => import('pages/Emission/EmissionDetail'));
const RequireAuth = React.lazy(() => import('components/Auth/RequireAuth'));
const RequireNoAuth = React.lazy(() => import('components/Auth/RequireNoAuth'));
const RequireUserContext = React.lazy(() => import('components/v2/RequireOrganisationContext'));
const ResetPasswordNoAuth = React.lazy(() => import('components/Auth/ResetPasswordNoAuth'));
const ActivateUser = React.lazy(() => import('components/Auth/ActivateUser'));
const ImportDataPage = React.lazy(() => import('pages/DataSource'));
const ImportDefinitionPage = React.lazy(() => import('pages/DataSource/ImportDefinition'));
const ImportJobsPage = React.lazy(() => import('pages/DataSource/ImportJobs'));
const SupplyChainDashboard = React.lazy(() => import('pages/SupplyChain/index'));
const SuppliersList = React.lazy(() => import('pages/SupplyChain/Suppliers'));
const Supplier = React.lazy(() => import('pages/SupplyChain/Supplier'));
const KPIScopeSettingsGRI = React.lazy(() => import('components/KPI/KPIScope/GRI'));
const KPIScopeSettingsESRS = React.lazy(() => import('components/KPI/KPIScope/ESRS'));
const KPIScopeSettingsGeneric = React.lazy(() => import('components/KPI/KPIScope/Generic'));
const ApprovalCenter = React.lazy(() => import('pages/ApprovalCenter/index'));
const ClimateRiskAnalyses = React.lazy(() => import('pages/ClimateRisk/Analyses'));
const ClimateRiskAnalysis = React.lazy(() => import('pages/ClimateRisk/components/Analyses/Analysis'));
const ClimateRiskActions = React.lazy(() => import('pages/ClimateRisk/Actions'));
const ClimateRisks = React.lazy(() => import('pages/ClimateRisk/Risks'));
const ClimateRiskSegments = React.lazy(() => import('pages/ClimateRisk/Segments'));
const ActionManagement = React.lazy(() => import('pages/SupplyChain/actionManagement'));
const ProductList = React.lazy(() => import('pages/SupplyChain/Components/ProductList'));
const ReportEditor = React.lazy(() => import('pages/ReportEditor'));
const KeyFiguresViewRef = React.lazy(() => import('pages/StatusDashboard/KeyFigureViewRef'));
const Validate2FA = React.lazy(() => import('pages/2FA/Validate'));

export default function generateRoutes() {
   return [
      {
         path: '/login',
         element: (
            <Suspense fallback={<FullPageLoadingIndicator />}>
               <RequireNoAuth>
                  <Login />
               </RequireNoAuth>
            </Suspense>
         ),
      },
      {
         path: '/validate',
         element: (
            <Suspense fallback={<FullPageLoadingIndicator />}>
               <RequireNoAuth>
                  <Validate2FA />
               </RequireNoAuth>
            </Suspense>
         ),
      },
      {
         path: '/resetpassword/:token',
         element: (
            <Suspense fallback={<FullPageLoadingIndicator />}>
               <ResetPasswordNoAuth />
            </Suspense>
         ),
      },
      {
         path: '/activate/:token',
         element: (
            <Suspense fallback={<FullPageLoadingIndicator />}>
               <ActivateUser />
            </Suspense>
         ),
      },

      {
         path: '/',
         element: (
            <Suspense fallback={<FullPageLoadingIndicator />}>
               <RequireAuth>
                  <RequireUserContext>
                     <Layout />
                  </RequireUserContext>
               </RequireAuth>
            </Suspense>
         ),
         children: [
            {
               key: 'reportEditor',
               path: 'reports/report-editor',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <ReportEditor />
                  </Suspense>
               ),
            },
            {
               path: '/reportingstandards/gri/scoping',
               subject: 'kpiScope',
               element: <KPIScopeSettingsGRI />,
            },

            {
               path: '/reportingstandards/esrs/scoping',
               subject: 'kpiScope',
               element: <KPIScopeSettingsESRS />,
            },

            {
               path: '/reportingstandards/:reportingStandard/scoping',
               subject: 'kpiScope',
               element: <KPIScopeSettingsGeneric />,
            },

            { path: '', element: <Navigate replace to={determineDefaultEntryPath()} /> },
            { path: 'dashboard', element: <Navigate replace to={`/${determineDashboardEntryPath()}`} /> },
            {
               key: 'dashboard',
               path: 'dashboard/:activeTab',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Overview />
                     </Suspense>
                  </Container>
               ),
            },

            { path: '/supplychain', element: <Navigate replace to="/supplychain/suppliers" /> },

            {
               key: 'supply-chain',
               path: 'supplychain/dashboard',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <SupplyChainDashboard />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'supply-chain',
               path: 'supplychain/suppliers',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <SuppliersList />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'supply-chain',
               path: 'supplychain/suppliers/:supplierId',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Supplier />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'supply-chain',
               path: 'supplychain/suppliers/:supplierId/:activeTab',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Supplier />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'supply-chain',
               path: 'supplychain/actions',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ActionManagement />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'supply-chain',
               path: 'supplychain/actions/:taskId',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ActionDetail />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'supply-chain',
               path: 'supplychain/products',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ProductList />
                     </Suspense>
                  </Container>
               ),
            },

            { path: '/climaterisk', element: <Navigate replace to="/climaterisk/analyses" /> },

            {
               key: 'climateRisk',
               path: 'climaterisk/analyses',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ClimateRiskAnalyses />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'climateRisk',
               path: 'climaterisk/analyses/:analysisId',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ClimateRiskAnalysis />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'climateRisk',
               path: 'climaterisk/actions',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ClimateRiskActions />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'climateRisk',
               path: 'climaterisk/risks',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ClimateRisks />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'climateRisk',
               path: 'climaterisk/segments',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ClimateRiskSegments />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'kpis',
               path: 'kpi/:kpiAreaSlug',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <KPIDetailPage />
                  </Suspense>
               ),
            },

            {
               key: 'kpis',
               path: 'kpi/:kpiAreaSlug/:kpiSlug',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <KPIDetailPage />
                  </Suspense>
               ),
            },

            {
               key: 'kpis',
               path: 'kpi/:kpiAreaSlug/:kpiSlug/:kpiSubIdOrSlug',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <KPIDetailPage />
                  </Suspense>
               ),
            },

            {
               key: 'kpis',
               path: 'kpi/:kpiAreaSlug/:kpiSlug/:kpiSubIdOrSlug/:kpiContentIdOrSlug',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <KPIDetailPage />
                  </Suspense>
               ),
            },

            {
               key: 'kpis',
               path: 'kpi/:kpiAreaSlug/:kpiSlug/:kpiSubIdOrSlug/:kpiContentIdOrSlug/:kpiFieldIdOrSlug',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <KPIDetailPage />
                  </Suspense>
               ),
            },

            {
               key: 'kpi',
               path: 'kpi',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <KPIDetailPage />
                  </Suspense>
               ),
            },

            {
               key: 'reports',
               path: 'reports',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Reports />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'report',
               path: 'reports/:reportId/documents',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Reports />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'report',
               path: 'reports/:reportId/documents/:documentId',
               element: (
                  <Container maxWidth={false} disableGutters sx={{ paddingY: 0 }}>
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ReportEditorPage />
                     </Suspense>
                  </Container>
               ),
            },

            { path: '/emissions', element: <Navigate replace to="/emissions/ghg/footprints" /> },
            { path: '/emissions/ghg', element: <Navigate replace to="/emissions/ghg/footprints" /> },
            {
               key: 'emissions',
               path: 'emissions/ghg/footprints',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <EmissionFootprints />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'emissions',
               path: 'emissions/ghg/results',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <EmissionResults />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'emissions',
               path: 'emissions/ghg/reduction-plan',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ReductionPlan />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'emissions',
               path: 'emissions/ghg/footprints/:footprintId',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <EmissionDetail />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'emissions',
               path: 'emissions/ghg/footprints/:footprintId/:activeSubSlug',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <EmissionDetail />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'emissions',
               path: 'emissions/ghg/footprints/:footprintId/:activeSubSlug/:fpCalcSlugOrId',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <EmissionDetail />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'emissions',
               path: 'emissions/ghg/footprints/:footprintId/:activeSubSlug/:fpCalcSlugOrId/:fpCalcFieldSlugOrId',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <EmissionDetail />
                     </Suspense>
                  </Container>
               ),
            },

            { path: '/taxonomy', element: <Navigate replace to="/taxonomy/businessactivities" /> },

            {
               key: 'taxonomy',
               path: 'taxonomy/businessactivities',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Taxonomy />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'minimum-safeguards',
               path: 'taxonomy/minimum-safeguards',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <MinimumSafeguards />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'taxonomyDetail',
               path: 'taxonomy/businessactivities/:businessActivityId',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <BusinessActivity />
                  </Suspense>
               ),
            },

            {
               key: 'taxonomyDetailWithObjective',
               path: 'taxonomy/businessactivities/:businessActivityId/:taxonomyObjectiveIdOrSlug',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <BusinessActivity />
                  </Suspense>
               ),
            },

            {
               key: 'taxonomyFinancials',
               path: 'taxonomy/financials',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Financials />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'taxonomyFinancials',
               path: 'taxonomy/results',
               element: (
                  <Container maxWidth={false}>
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <TaxonomyDisclosures />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'tasks',
               path: 'tasks',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Tasks />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'tasksdetail',
               path: 'tasks/:taskId',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <TasksDetail />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'actions',
               path: 'actions',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <Actions />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'actiondetail',
               path: 'actions/:taskId',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ActionDetail />
                     </Suspense>
                  </Container>
               ),
            },

            {
               key: 'user',
               path: 'users',
               children: [
                  {
                     path: ':username',
                     element: (
                        <Container maxWidth="xl">
                           <Suspense fallback={<FullPageLoadingIndicator />}>
                              <Profile />
                           </Suspense>
                        </Container>
                     ),
                  },
                  {
                     path: ':username/:activeTab',
                     element: (
                        <Container maxWidth="xl">
                           <Suspense fallback={<FullPageLoadingIndicator />}>
                              <Profile />
                           </Suspense>
                        </Container>
                     ),
                  },
               ],
            },

            { path: '/settings', element: <Navigate replace to="/settings/organisations" /> },
            {
               key: 'settings-currencies',
               path: 'settings/:settingsKey',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <Settings />
                  </Suspense>
               ),
            },

            {
               key: 'settings-currencies',
               path: 'settings/:categoryKey/:settingsKey',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <Settings />
                  </Suspense>
               ),
            },

            {
               key: 'reports-subpages',
               path: 'reports/key-figures',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <ReportSubpages key="key-figures" />
                  </Suspense>
               ),
            },
            {
               key: 'reports-subpages',
               path: 'reports/emissions',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <ReportSubpages key="emissions" />
                  </Suspense>
               ),
            },
            {
               key: 'reports-subpages',
               path: 'reports/actions',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <ReportSubpages key="actions" />
                  </Suspense>
               ),
            },
            {
               key: 'status',
               path: 'status',
               children: [
                  {
                     /*{
                     key: 'status-page',
                     path: 'status-page',
                     element: (
                        <Suspense fallback={<FullPageLoadingIndicator />}>
                           <StatusDashboard />
                        </Suspense>
                     ),
                  },*/
                  },
                  // {
                  //    key: 'status-overview',
                  //    path: 'status-overview',
                  //    element: (
                  //       <Suspense fallback={<FullPageLoadingIndicator />}>
                  //          <StatusDashboardView />
                  //       </Suspense>
                  //    ),
                  // },
                  // Key Figures view
                  {
                     key: 'key-figures',
                     path: 'key-figures',
                     element: (
                        <Suspense fallback={<FullPageLoadingIndicator />}>
                           {/* <KeyFiguresView /> */}
                           <KeyFiguresViewRef />
                        </Suspense>
                     ),
                  },
               ],
            },

            {
               path: '/403',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <NotAllowed />
                  </Suspense>
               ),
            },

            {
               path: '/404',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <NotFound />
                  </Suspense>
               ),
            },
            {
               path: '*',
               element: (
                  <Suspense fallback={<FullPageLoadingIndicator />}>
                     <NotFound />
                  </Suspense>
               ),
            },
            {
               path: '/data-source',
               element: <Navigate replace to="/data-source/import-data" />,
            },
            {
               path: '/data-source/import-data',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ImportDataPage />
                     </Suspense>
                  </Container>
               ),
            },
            {
               path: '/data-source/import-jobs',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ImportJobsPage />
                     </Suspense>
                  </Container>
               ),
            },
            {
               path: '/data-source/import-definition',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ImportDefinitionPage />
                     </Suspense>
                  </Container>
               ),
            },
            {
               key: 'approval-center',
               path: '/approval-center',
               element: (
                  <Container maxWidth="xl">
                     <Suspense fallback={<FullPageLoadingIndicator />}>
                        <ApprovalCenter />
                     </Suspense>
                  </Container>
               ),
            },
         ],
      },
   ];
}
